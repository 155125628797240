<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row align="center" style="margin: 0 20px" >
      <v-col cols="6" v-if="showDeparture" :offset="!showDestination? 3 : 0">
        <v-card :elevation="elevation" style="padding: 15px; background-color: rgb(236, 239, 241);" >
          <v-card-title style="text-transform: uppercase;">
            {{ tLabel('Departure') }}
            <v-spacer></v-spacer>
            <v-icon large class="mr-2" style="float: right;">mdi-ray-start</v-icon>
          </v-card-title>
          <div style="padding: 0 15px;" >
            <v-text-field :dense="dense" solo clearable :disabled="!editable" v-model="itineraryVisit.departure" />
            <v-autocomplete :dense="dense" solo clearable :disabled="!editable" :label="tLabel('Locode')" v-model="itineraryVisit.departureLocode" :items="locodes" />
            <v-autocomplete :dense="dense" solo clearable :disabled="!editable" :label="tLabel('Company')" v-model="itineraryVisit.departureCompanyId" :items="allCompanies" item-value="id" item-text="code" />
          </div>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="showDestination" :offset="!showDeparture? 3 : 0">
        <v-card :elevation="elevation" style="padding: 15px; background-color: rgb(236, 239, 241);" v-if="showDestination">
          <v-card-title style="text-transform: uppercase;">
            <v-icon large class="mr-2" style="float: right;">mdi-ray-end</v-icon>
            <v-spacer></v-spacer>
            {{ tLabel('Destination') }}
          </v-card-title>
          <div style="padding: 0 15px;" >
            <v-text-field :dense="dense" solo clearable :disabled="!editable" v-model="itineraryVisit.destination" />
            <v-autocomplete :dense="dense" solo clearable :disabled="!editable" :label="tLabel('Locode')" v-model="itineraryVisit.destinationLocode" :items="locodes" />
            <v-autocomplete :dense="dense" solo clearable :disabled="!editable" :label="tLabel('Company')" v-model="itineraryVisit.destinationCompanyId" :items="allCompanies" item-value="id" item-text="code" />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" style="margin-bottom: 20px;">
      <v-col cols="4">
        <v-card style="margin-top: 20px; padding: 20px 20px 0; background-color: rgb(236, 239, 241)" :elevation="elevation">
          <v-autocomplete :dense="dense" outlined clearable :disabled="!editable" :label="tLabel('POD')" v-model="itineraryVisit.podLocode" :items="locodes"></v-autocomplete>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card style="margin-top: 20px; padding: 20px 20px 0; background-color: rgb(236, 239, 241)" :elevation="elevation">
          <v-autocomplete :dense="dense" outlined clearable :disabled="!editable" :label="tLabel('SPOD')" v-model="itineraryVisit.spodLocode" :items="locodes"></v-autocomplete>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card style="margin-top: 20px; padding: 20px 20px 0; background-color: rgb(236, 239, 241)" :elevation="elevation">
          <v-autocomplete :dense="dense" outlined clearable :disabled="!editable" :label="tLabel('POL')" v-model="itineraryVisit.polLocode" :items="locodes"></v-autocomplete>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ItineraryForm",
  data() {
    return {
      locodes: [],
      allCompanies: [],
      elevation: 4
    };
  },
  props: {
    itineraryVisit: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDeparture: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDestination: {
      type: Boolean,
      required: false,
      default: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  async mounted() {
    this.locodes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "locodes/list");
    this.allCompanies = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/all");
  },
};
</script>

<style></style>