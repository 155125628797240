<template>
  <v-card rounded="0">
    <v-card-title class="text-uppercase font-weight-bold">
      <span>
        <v-icon large>mdi-earth</v-icon>
        <span class="mx-2 px-2">{{ tLabel("Itinerary") }}</span>
      </span>
    </v-card-title>
    <v-divider class="mx-4 warning"></v-divider>
    <v-card-text>
      <ItineraryForm :dense="dense" :itineraryVisit="itineraryVisit" />
    </v-card-text>
  </v-card>
</template>

<script>
import ItineraryForm from "@/components/yard/ItineraryForm";

export default {
  name: "ItineraryCard",
  data() {
    return {};
  },
  props: {
    itineraryVisit: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { ItineraryForm },
};
</script>

<style></style>
